import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Brand = styled.a`
  color: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 2rem;
  }

  @media (max-width: 960px) {
    mix-blend-mode: ${({ theme }) =>
      theme === 'light' ? 'unset' : 'difference'};
  }

  @media (max-width: 786px) {
    h1 {
      font-size: 1rem;
    }
  }
`

export const Logo = styled.img`
  height: 3rem;
  margin-right: 1rem;

  @media (max-width: 786px) {
    height: 2rem;
  }
`
