import { Container } from 'components/common'
import { Link } from 'gatsby'
import { ThemeContext } from 'providers/ThemeProvider'
import React, { useContext } from 'react'
import logo from '../../../../assets/icons/favicon-512.png'
import NavbarLinks from '../NavbarLinks'
import { Brand, Logo, Wrapper } from './styles'

const Navbar = ({ showNavbarLinks = true }) => {
  const { theme } = useContext(ThemeContext)

  return (
    <Wrapper as={Container}>
      <Brand as={Link} to='/' theme={theme}>
        <Logo src={logo} />
        <h1>Express Transcriber</h1>
      </Brand>
      {showNavbarLinks ? (
        <NavbarLinks desktop />
      ) : (
        <Link to='/' style={{ fontWeight: 700, fontSize: '20px' }}>
          Home
        </Link>
      )}
    </Wrapper>
  )
}

export default Navbar
