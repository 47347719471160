module.exports = {
  defaultTitle: 'Express Transcriber',
  author: 'Express Transcriber',
  url: 'https://www.expresstranscriber.com',
  legalName: 'Express Transcriber',
  defaultDescription: 'We provide various transcription services!',
  socialLinks: {},
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: 'https://www.facebook.com/expresstranscriber/',
  },
  address: {
    city: 'City',
    region: 'Region',
    country: 'Country',
    zipCode: 'ZipCode',
  },
  contact: {
    email: 'support@expresstranscriber.com',
    phone: '+1 (801)-885-8377',
  },
  foundingDate: '2021',
}
