import React from 'react'
import { Container } from 'components/common'
import { Wrapper, Flex, Links, Details } from './styles'
import facebook from '../../../assets/icons/facebook.svg'
import linkedIn from '../../../assets/icons/linked-in.svg'

export const Footer = () => (
  <Wrapper>
    <Flex as={Container}>
      <Details>
        <h2>Express Transcriber</h2>
        <span>© All rights are reserved | {new Date().getFullYear()}</span>
      </Details>
      <Details>
        <h3>Contact</h3>
        <p>Email: support@expresstranscriber.com</p>
        <p>Phone: +1 (801)-885-8377</p>
        <Links>
          <a
            href='https://www.facebook.com/expresstranscriber/'
            target='_blank'
            rel='noopener noreferrer'
            aria-label={`follow me on Facebook`}
          >
            <img width='24' src={facebook} alt='Facebook' />
          </a>
          <a
            href='https://www.linkedin.com/company/express-transcriber/'
            target='_blank'
            rel='noopener noreferrer'
            aria-label={`follow me on LinkedIn`}
          >
            <img width='24' src={linkedIn} alt='LinkedIn' />
          </a>
        </Links>
      </Details>
    </Flex>
  </Wrapper>
)
