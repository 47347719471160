import { ThemeContext } from 'providers/ThemeProvider'
import React, { useContext } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Wrapper } from './styles'

const NavbarLinks = ({ desktop }) => {
  const { theme } = useContext(ThemeContext)

  return (
    <Wrapper desktop={desktop} theme={theme}>
      <a href='/upload'>Automated Transcription</a>
      <AnchorLink href='#about'>About</AnchorLink>
      <AnchorLink href='#process'>How It Works</AnchorLink>
      <AnchorLink href='#contact'>Contact</AnchorLink>
    </Wrapper>
  )
}

export default NavbarLinks
